import React, { useState } from 'react';
import logoIBM from '../../../assets/images/image-header.png';
import vivarioOne from '../../../assets/images/partner/vivario1.png';
import vivarioTwo from '../../../assets/images/partner/vivario2.png';
import Button from '../../common/Button/Button';
import './Header.scss';
import ModalForm from '../../ui/ModalForm/ModalForm';
import { useUser } from '../../hooks/UserContext';

const Header = () => {

  const links = [
    {
      "title": "Como a tecnologia é usada em shows ao vivo?",
      "link": "https://skills.yourlearning.ibm.com/activity/MDL-412?utm_campaign=open-Mastertech",
    },
    {
      "title": "Como a tecnologia é utilizada nos jogos de tênis?",
      "link": "https://skills.yourlearning.ibm.com/activity/MDL-505?utm_campaign=open-Mastertech",
    },
    {
      "title": "Fundamentos da Inteligência Artificial",
      "link": "https://skills.yourlearning.ibm.com/activity/PLAN-7913EE1DB030?utm_campaign=open-Mastertech",
    },
    {
      "title": "Fundamentos do Desenvolvimento Web",
      "link": "https://skills.yourlearning.ibm.com/activity/PLAN-8749C02A78EC?utm_campaign=open-Mastertech",
    }
  ]

  return (<div className='Header'>
    <div className='container-header-top-desktop'>
      <div className='info-header'>
        <p>Formação on-line gratuita</p>
        <h1>Match<span>!</span></h1>
        <h2>Inscrições encerradas.</h2>

        <p className={"info-letter"}>
          Queremos agradecer por ter feito parte do Programa Match! Sua dedicação e participação foram essenciais para o sucesso dessa jornada, e somos gratos por termos acompanhado sua evolução. O Programa Match! está chegando ao fim, e as inscrições serão encerradas. Mas, se você já está inscrito, não se preocupe! Seguiremos com o suporte e atendimento até março de 2025, para que você possa concluir suas atividades e garantir seu certificado.
        </p>
        <p className={"info-letter info-letter-headline"}>
          E sua jornada de aprendizado não precisa <span>parar por aqui</span>! 📚
        </p>
        <p className={"info-letter"}>
          Graças à colaboração com a plataforma IBM SkillsBuild, você pode continuar aprendendo gratuitamente. Sugerimos os cursos de como a inovação pode impactar a sua vida:
        </p>

        {links.map((link, index) => {
          return <Button href={link.link}>{link.title}</Button>
        })}

      </div>
      <img alt='Logo da Impulso' src={logoIBM} />
    </div>



    <div className='container-header-top-mobile'>
      <div className='info-header'>
        <p>Formação on-line gratuita</p>
        <h1>Match<span>!</span></h1>
        <h2>Inscrições encerradas.</h2>
        <img alt='Logo da Impulso' src={logoIBM} />

        <p className={"info-letter"}>
          Queremos agradecer por ter feito parte do Programa Match! Sua dedicação e participação foram essenciais para o sucesso dessa jornada, e somos gratos por termos acompanhado sua evolução. O Programa Match! está chegando ao fim, e as inscrições serão encerradas. Mas, se você já está inscrito, não se preocupe! Seguiremos com o suporte e atendimento até março de 2025, para que você possa concluir suas atividades e garantir seu certificado.
        </p>
        <p className={"info-letter info-letter-headline"}>
          E sua jornada de aprendizado não precisa <span>parar por aqui</span>! 📚
        </p>
        <p className={"info-letter"}>
          Graças à colaboração com a plataforma IBM SkillsBuild, você pode continuar aprendendo gratuitamente. Sugerimos os cursos de como a inovação pode impactar a sua vida:
        </p>

        {links.map((link, index) => {
          return <Button href={link.link}>{link.title}</Button>
        })}

      </div>
    </div>
  </div>);
}

export default Header;
